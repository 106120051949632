<script setup lang="ts"></script>
<template>
    <div class="min-h-dvh flex flex-column surface-ground">
        <div class="surface-ground" style="height: 210px">
            <div
                class="py-3 px-5 flex align-items-center justify-content-between relative lg:static"
                style="min-height: 80px"
            >
                <img
                    src="/logo_long.png"
                    alt="Image"
                    height="80"
                    class="mr-0 lg:mr-3"
                />
                <a
                    v-ripple
                    class="cursor-pointer block lg:hidden text-black hover:text-black hover:bg-amber-300 p-ripple"
                    v-styleclass="{
                        selector: '@next',
                        enterFromClass: 'hidden',
                        enterActiveClass: 'scalein',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'fadeout',
                        hideOnOutsideClick: true,
                    }"
                >
                    <i class="pi pi-bars text-4xl"></i>
                </a>
                <div
                    class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 surface-ground"
                >
                    <ul></ul>
                    <ul
                        class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-yellow-300 lg:border-top-none"
                    >
                        <li>
                            <a
                                href="https://app.snap-pix.co.uk/"
                                v-ripple
                                class="flex px-6 p-3 lg:px-3 lg:py-2 md:bg-amber-500 md:shadow-2 align-items-center text-black md:text-white font-medium border-round cursor-pointer hover:md:bg-amber-600 transition-duration-150 p-ripple"
                            >
                                <i class="pi pi-user mr-2"></i>
                                <span>Login</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="p-5 flex flex-column flex-auto" style="margin-top: -8rem">
            <div class="surface-section flex-auto rounded-t-[50px]">
                <router-view />
            </div>
        </div>
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="surface-section">
                <div class="grid">
                    <div
                        class="col-12 md:col-3 flex flex-wrap justify-content-start md:mb-0 mb-3"
                    >
                        <img
                            src="/logo_long.png"
                            alt="footer sections"
                            height="100"
                            class="mr-3"
                        />
                    </div>
                    <div class="col-12 md:col-3">
                        <div class="text-black mb-3">
                            <i
                                class="pi pi-inbox surface-card border-round p-1 mr-2"
                            ></i
                            >hello@snap-pix.co.uk
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
